import { PropsWithChildren } from 'react'

import { AppProvider } from '@shopify/polaris'
import translationsCs from '@shopify/polaris/locales/cs.json'
import translationsEn from '@shopify/polaris/locales/en.json'

import { getTranslation } from '../util/getTranslation'

export function I18nProvider({ children }: PropsWithChildren<{}>) {
  return (
    <AppProvider i18n={getTranslation(translationsCs, translationsEn)}>
      {children}
    </AppProvider>
  )
}
