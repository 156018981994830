import React from 'react'

import {
  AUTH_TOKEN_KEY,
  createApi,
} from '../api'
import App from './App'
import {
  AuthProvider,
  TAuthContext,
} from './AuthContext'

export function Auth() {
  const [auth, setAuth] = React.useState<TAuthContext | null>(null)

  React.useEffect(() => {
    const api = createApi()
    api
      .authenticate()
      .then(({ token, account, shopAdminUrl, shopGatewayUrl }) => {
        if (!account) {
          // This can happen if account is missing from DB for some reason
          // or the account need to be reinstalled
          // Since we can't redirect based on background request, we have
          // to do it here to initiate OAuth flow.
          window.location.replace(`/app${window.location.search}`)
          return
        }

        // stored token is used by API calls
        window.sessionStorage.setItem(AUTH_TOKEN_KEY, token)

        setAuth({
          account,
          shopAdminUrl,
          shopGatewayUrl,
          async refreshAccount() {
            const { account } = await api.readAccount()
            if (account === null) {
              // poorly handled, but it shouldn't happen
              window.location.replace(`/error?message=account not found`)
              throw new Error('Account vanished when refreshing, duh?')
            }
            setAuth((cfg) => ({ ...cfg!, account }))
            return account
          },
        })
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(err)
          return
        }
        window.location.replace(`/error?message=${err.message}`)
      })
  }, [])

  if (!auth) {
    return null
  }

  return (
    <AuthProvider value={auth}>
      <App />
    </AuthProvider>
  )
}
