import React from 'react'

import { useForm } from 'react-hook-form'

import {
  createApi,
  TApi,
} from '../api'
import { useAuth } from '../components/AuthContext'

export function useAccountSettingsForm(
  dispatcher: (api: TApi, formValues: Dictionary) => Promise<void>,
) {
  const config = useAuth()
  const form = useForm({
    defaultValues: {
      contactEmail: config.account.contactEmail || '',
      contactPhone: config.account.contactPhone || '',
    },
  })

  const [saveStatus, setSaveStatus] = React.useState<
    'submit' | 'success' | 'failure'
  >('submit')

  const onSubmit = form.handleSubmit(async (values) => {
    const api = createApi()
    try {
      await dispatcher(api, values)
      setSaveStatus('success')
    } catch {
      setSaveStatus('failure')
    }
  })

  return { form, saveStatus, setSaveStatus, onSubmit }
}
