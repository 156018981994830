import {
  useController,
  useFormContext,
} from 'react-hook-form'
import { GoPay } from 'shopify-payments-types'

import {
  Card,
  Select,
  SelectOption,
} from '@shopify/polaris'

import { useAccount } from '../hooks/useAccount'
import { getTranslation } from '../util/getTranslation'

export function SettingsLanguage({ languages }: { languages: SelectOption[] }) {
  const account = useAccount<GoPay.TSegmentAccount>()
  const { setValue } = useFormContext()
  const { field } = useController({
    name: 'language',
    defaultValue: account.segmentConfig.language || '',
  })

  return (
    <Card.Section>
      <Select
        helpText={getTranslation(
          'Zvolte jazyk pokud víte, že vaši zákazníci jinou řečí nemluví.',
          "Choose a language if you know your customers don't speak another language. ",
        )}
        label={getTranslation(
          'Preferovaný jazyk brány',
          'Preferred gateway language',
        )}
        value={field.value}
        options={languages}
        onChange={(selected) => {
          setValue(field.name, selected, {
            shouldDirty: true,
            shouldTouch: true,
          })
        }}
      />
    </Card.Section>
  )
}
