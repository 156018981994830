import { ReactNode } from 'react'

import {
  useController,
  useFormContext,
} from 'react-hook-form'
import { GoPay } from 'shopify-payments-types'

import {
  Card,
  ChoiceList,
  Link,
} from '@shopify/polaris'

import { useAccount } from '../hooks/useAccount'
import { getTranslation } from '../util/getTranslation'

export function GoPayDefaultInstrument() {
  const account = useAccount<GoPay.TSegmentAccount>()
  const { setValue } = useFormContext()
  const { field } = useController({
    name: 'defaultPaymentInstrument',
    defaultValue: account.segmentConfig.defaultPaymentInstrument || '',
  })

  return (
    <Card.Section>
      <ChoiceList
        selected={[field.value]}
        title={getTranslation(
          'Preferovaná platební metoda',
          'Preferred payment method',
        )}
        choices={getInstruments().map(({ label, value, help, helpPath }) => ({
          label,
          value,
          helpText: help || (
            <Link external url={`https://www.gopay.com/${helpPath}`}>
              {getTranslation('Další informace', 'More information')}
            </Link>
          ),
        }))}
        onChange={(selected) =>
          setValue(field.name, selected[0], {
            shouldDirty: true,
            shouldTouch: true,
          })
        }
      />
    </Card.Section>
  )
}

type TInstrument = {
  value: GoPay.TPaymentInstrument
  label: string
  helpPath?: string
  help?: ReactNode
}

function getInstruments(): ReadonlyArray<TInstrument> {
  return [
    {
      value: '' as any,
      label: getTranslation('Zákazník si vybere', 'Customer will choose'),
      help: (
        <span>
          {getTranslation(
            `Můžete vybrat platební metodu, kterou chcete aby zákazník využíval nejčastěji.
             I přes toto nastavení si však zákazník může vybrat jinou (z vámi povolených) možností.`,
            `You can choose payment method that you want the customer used it most often.
            However, despite this setting, the customer can choose another option (from the ones you allow)
            `,
          )}
        </span>
      ),
    },
    {
      value: 'PAYMENT_CARD',
      label: getTranslation('Platba kartou', 'Card payment'),
      helpPath: getTranslation(
        'cs/online-platba-kartou',
        'en/online-card-payments',
      ),
    },
    {
      value: 'BANK_ACCOUNT',
      label: getTranslation('Bankovní převod', 'Bank transfer'),
      helpPath: getTranslation(
        'cs/online-bankovni-prevod',
        'en/online-bank-tranfer',
      ),
    },

    // {
    //   value: 'GPAY',
    //   label: 'Google pay',
    //   helpPath: getTranslation('cs/google-pay', 'en/google-pay'),
    // },

    // {
    //   value: 'APPLE_PAY',
    //   label: 'Apple pay',
    //   helpPath: getTranslation('cs/apple-pay', 'en/apple-pay'),
    // },

    // {
    //   value: 'PAYPAL',
    //   label: 'Paypal',
    //   help: (
    //     <span>
    //       {getTranslation(
    //         'Pro aktivaci PayPal následujte',
    //         'For PayPal activation follow',
    //       )}{' '}
    //       <Link
    //         url={getTranslation(
    //           'https://help.gopay.com/cs/tema/mam-platebni-branu/chci-pouzivat-gopay-obchodni-ucet/nastaveni-platebnich-metod/jak-aktivuji-paypal',
    //           'https://help.gopay.com/en/knowledge-base/gopay-account/gopay-business-account/settings-of-payment-methods/how-do-i-activate-the-paypal-payment-method',
    //         )}
    //         external
    //       >
    //         {getTranslation('tento návod', 'this manual')}
    //       </Link>
    //     </span>
    //   ),
    // },
  ]
}
