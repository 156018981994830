import { FormProvider } from 'react-hook-form'

import {
  Button,
  Card,
  Form,
  SelectOption,
  Stack,
  Toast,
} from '@shopify/polaris'

import { useAccountSettingsForm } from '../hooks/useAccountSettingsForm'
import { segment } from '../util/createSegmentPicker'
import { getTranslation } from '../util/getTranslation'
import { useAuth } from './AuthContext'
import { GoPayDefaultInstrument } from './GoPayDefaultInstrument'
import { SettingsContactCard } from './SettingsContactCard'
import { SettingsLanguage } from './SettingsLanguage'
import { SettingsPaymentLabel } from './SettingsPaymentLabel'

GoPayAccountSettings.segment = segment('gopay')

export function GoPayAccountSettings() {
  const config = useAuth()
  const { form, saveStatus, setSaveStatus, onSubmit } = useAccountSettingsForm(
    (api, formValues) => api.saveConfig(formValues),
  )

  if (config.account.activationStatus === 'new') {
    // Don't bother user with too much stuff initially
    return null
  }

  return (
    <Card title={getTranslation('Nastavení brány', 'Gateway settings')}>
      <FormProvider {...form}>
        <Form onSubmit={onSubmit}>
          <SettingsContactCard />
          <GoPayDefaultInstrument />
          <SettingsLanguage languages={getLanguages()} />
          <SettingsPaymentLabel />
          <Card.Section>
            <Stack distribution="trailing">
              <Button primary submit disabled={form.formState.isSubmitting}>
                {getTranslation('Uložit nastavení', 'Save settings')}
              </Button>
            </Stack>
          </Card.Section>
        </Form>
      </FormProvider>
      {saveStatus === 'success' && (
        <Toast
          content={getTranslation('Nastavení uloženo', 'Settings saved')}
          onDismiss={() => setSaveStatus('submit')}
        />
      )}
      {saveStatus === 'failure' && (
        <Toast
          content={getTranslation('Ukládání selhalo', 'Saving failed')}
          error
          onDismiss={() => setSaveStatus('submit')}
        />
      )}
    </Card>
  )
}

function getLanguages(): SelectOption[] {
  return [
    { value: '' as any, label: 'Automaticky' },
    { value: 'CS', label: 'Čeština' },
    { value: 'EN', label: 'Angličtina' },
    { value: 'SK', label: 'Slovenština' },
    { value: 'DE', label: 'Němčina' },
    { value: 'RU', label: 'Ruština' },
    { value: 'PL', label: 'Polština' },
    { value: 'HU', label: 'Maďarština' },
    { value: 'FR', label: 'Francouzština' },
    { value: 'RO', label: 'Rumunština' },
    { value: 'BG', label: 'Bulharština' },
    { value: 'HR', label: 'Chorvatština' },
    { value: 'IT', label: 'Italština    ' },
  ]
}
