import React from 'react'

import {
  FormProvider,
  useForm,
} from 'react-hook-form'
import { BSecure } from 'shopify-payments-types'

import {
  Banner,
  Button,
  Card,
  Form,
  FormLayout,
  Heading,
  Link,
  Stack,
  TextContainer,
  TextStyle,
} from '@shopify/polaris'

import { createApi } from '../api'
import { segment } from '../util/createSegmentPicker'
import { useAuth } from './AuthContext'
import { FormTextField } from './FormTextField'

type TProps = {
  onSuccess(): void
}

BSecureCredentialsForm.segment = segment('bsecure')

const registrationFormUrl = 'https://builder.bsecure.pk/signup'

export function BSecureCredentialsForm({ onSuccess }: TProps) {
  const config = useAuth()
  const {
    segmentCredentials,
    segmentConfig: { sandboxCredentials },
  } = config.account as BSecure.TSegmentAccount
  const form = useForm<BSecure.TSegmentCredentials>({
    reValidateMode: 'onChange',
  })

  const errorMessageRef = React.useRef<null | string>(null)
  const [testMode, setTestMode] = React.useState(true)
  const [status, setStatus] = React.useState<
    'init' | 'wrong-env' | 'failed' | 'done'
  >('init')

  React.useEffect(() => {
    if (testMode) {
      form.reset({
        clientId: sandboxCredentials?.clientId,
        clientSecret: sandboxCredentials?.clientSecret,
        storeId: sandboxCredentials?.storeId,
      })
    } else {
      form.reset({
        clientId: segmentCredentials.clientId,
        clientSecret: segmentCredentials.clientSecret,
        storeId: segmentCredentials.storeId,
      })
    }
  }, [testMode, form, segmentCredentials, sandboxCredentials])

  const onSubmit = form.handleSubmit(async (values) => {
    setStatus('init')
    const api = createApi()
    const { success, code, errorMessage } = await api.verifyCredentials(
      values,
      testMode,
    )
    if (success) {
      setStatus('done')
      if (testMode) {
        setTestMode(false)
      } else {
        onSuccess()
      }
    } else if (code === 'wrong-env') {
      setStatus('wrong-env')
    } else {
      errorMessageRef.current = errorMessage ?? null
      setStatus('failed')
    }
  })

  return (
    <Card sectioned title="Credentials for connecting with bSecure">
      <Stack vertical>
        <TextContainer>
          <p>
            Already have a bSecure account set up? We need your credentials for
            communication with the payment gateway. You can find these in{' '}
            <Link url="https://builder.bsecure.pk/integration-live" external>
              bSecure Builder Portal
            </Link>
            .
          </p>
          <p>
            <TextStyle variation="strong">
              {testMode
                ? 'Input Sandbox Credentials first.'
                : 'Now fill in the credentials for making production payments. These differ from the credentials you entered before!'}
            </TextStyle>
          </p>
        </TextContainer>
        <FormProvider {...form}>
          <Form onSubmit={onSubmit}>
            <FormLayout>
              <FormTextField name="clientId" label="Client ID" />
              <FormTextField name="clientSecret" label="Client secret" />
              <FormTextField name="storeId" label="Store ID" />
              <Stack distribution="trailing">
                <Button primary submit disabled={form.formState.isSubmitting}>
                  {`Verify ${testMode ? 'test' : 'production'} credentials`}
                </Button>
              </Stack>
              {status === 'wrong-env' && (
                <Banner status="critical">
                  <Heading>Credentials are entered incorrectly.</Heading>
                  <p>
                    {testMode
                      ? "You've entered credentials for Live environment. Please use credentials from Sandbox tab."
                      : "You've entered credentials for Sandbox environment. Please use credentials from Production / Live tab."}
                  </p>
                </Banner>
              )}
              {status === 'failed' && (
                <Banner status="critical">
                  <Heading>Credentials verification failed.</Heading>
                  <p>
                    <TextStyle variation="negative">
                      {errorMessageRef.current ||
                        'Unexpected error occurred on provider side.'}
                    </TextStyle>
                  </p>
                </Banner>
              )}
            </FormLayout>
          </Form>
        </FormProvider>
        <TextContainer>
          <Heading>Don't have a bSecure account yet?</Heading>
          <p>
            You have to{' '}
            <Link url={registrationFormUrl} external>
              fill in the registration form
            </Link>{' '}
            and as soon as you have all the information required, use the same
            link which has lead you to this page.
          </p>
        </TextContainer>
      </Stack>
    </Card>
  )
}
