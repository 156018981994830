import { FormProvider } from 'react-hook-form'

import {
  Button,
  Card,
  Form,
  Stack,
  Toast,
} from '@shopify/polaris'

import { useAccountSettingsForm } from '../hooks/useAccountSettingsForm'
import { segment } from '../util/createSegmentPicker'
import { getTranslation } from '../util/getTranslation'
import { useAuth } from './AuthContext'
import { SettingsContactCard } from './SettingsContactCard'
import { SettingsPaymentLabel } from './SettingsPaymentLabel'

BSecureAccountSettings.segment = segment('bsecure')

export function BSecureAccountSettings() {
  const config = useAuth()
  const { form, saveStatus, setSaveStatus, onSubmit } = useAccountSettingsForm(
    (api, formValues) => api.saveConfig(formValues),
  )

  if (config.account.activationStatus === 'new') {
    // Don't bother user with too much stuff initially
    return null
  }

  return (
    <Card title={getTranslation('Nastavení brány', 'Gateway settings')}>
      <FormProvider {...form}>
        <Form onSubmit={onSubmit}>
          <SettingsContactCard />
          <SettingsPaymentLabel />
          <Card.Section>
            <Stack distribution="trailing">
              <Button primary submit disabled={form.formState.isSubmitting}>
                {getTranslation('Uložit nastavení', 'Save settings')}
              </Button>
            </Stack>
          </Card.Section>
        </Form>
      </FormProvider>
      {saveStatus === 'success' && (
        <Toast
          content={getTranslation('Nastavení uloženo', 'Settings saved')}
          onDismiss={() => setSaveStatus('submit')}
        />
      )}
      {saveStatus === 'failure' && (
        <Toast
          content={getTranslation('Ukládání selhalo', 'Saving failed')}
          error
          onDismiss={() => setSaveStatus('submit')}
        />
      )}
    </Card>
  )
}
