import { SegmentKey } from 'shopify-payments-types'

type TSegmentSpecific = { segment: SegmentKey }

export function createSegmentPicker(segment: SegmentKey) {
  return function pickBySegment<T extends TSegmentSpecific>(
    ...pickFrom: T[]
  ): T | React.FunctionComponent {
    return pickFrom.find((item) => item.segment === segment) || (() => null)
  }
}

export function segment(segmentKey: SegmentKey): SegmentKey {
  return segmentKey
}
