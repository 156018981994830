import { Badge } from '@shopify/polaris'

import { getTranslation } from '../util/getTranslation'
import { useAuth } from './AuthContext'

export function ActivationStatusBadge() {
  const { account } = useAuth()
  switch (account.activationStatus) {
    case 'new':
      return (
        <Badge status="attention">
          {getTranslation('Neaktivní', 'Inactive')}
        </Badge>
      )
    case 'verified':
      return (
        <Badge status="info">{getTranslation('Ověřeno', 'Verified')}</Badge>
      )
    default:
      return null
  }
}
